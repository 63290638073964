import en from "@/lang/en/index";
import fr from "@/lang/fr/index";

export default defineI18nConfig(() => ({
  legacy: false, // 是否兼容之前
  locale: "en",
  fallbackLocale: "en", // 兼容不到就用en
  messages: {
    en,
    fr
  }
}))
