export default {
  headerMenu: "Menu",
  headerSearch: "Search",
  headerHowItWorks: "How it Works",
  headerJoinNow: "JOIN NOW",
  headerAccount: "Account",
  headerWishlist: "Wishlist",
  headerBNTO: "BNTO",

  popoverLoginHello: "HELLO!",
  popoverSubtitle1: "Already have an account?",
  popoverSubtitle2: "Create an account and subscribe to rent",

  footerAboutUs: "ABOUT US",
  footerOurStory: "Our Story",
  footerBuyNewFromVIAVIA: "Buy New From VIAVIA",
  footerHelp: "HELP",
  footerFAQSupport: "FAQ + Support",
  footerContactUs: "Contact us",
  footerHowItWorks: "How it works",
  footerSizeCharts: "Size Charts",
  footerExplore: "EXPLORE",
  footerExploreAllProducts: "Explore All Products",
  footerBrands: "Brands",
  footerLegal: "LEGAL",
  footerPrivacyPolicy: "Privacy Policy",
  footerTermsConditions: "Terms + Conditions",
  footerStayConnected: "STAY CONNECTED",

  searchInputPlaceholder: "Looking for something?",
  streamerWelcome: "WELCOME!",
  streamer$50Off: "GET $50 OFF YOUR FIRST BNTO",
  streamerLeftTips: "Enter your email address and get your discount code!",
  streamerInputPlaceholder: "",
  streamerGetADiscount: "GET A DISCOUNT",
  streamerThankYou: "Thank you! Please check you inbox for your discount code.",
  streamerRightTipsStart: "By signing up, you will receive BNTO offers, promotions and other commercial messages. You are also agreeing to BNTO’s Privacy Policy. You may unsubscribe at any time.",
  streamerRightTipsPrivacyPolicy: "Privacy Policy",
  streamerRightTipsEnd: ". You can opt out anytime.",

  introduceTitle: "YOUR MONTHLY WARDROBE",
  introduceQuestion: "WHAT IS BNTO?",
  introduceParagraph1: "Don't limit renting clothes to special events—integrate it into your daily wardrobe too. Pick your favorite 6 styles and create a BNTO box for your monthly outfits—a solution for constant freshness without fretting over fast fashion waste, closet space, or laundry.",
  introduceParagraph2: "BNTO RENT, powered by AI, is about help you unlock new personal style, and dressing up for your everyday life.",
  introduceParagraph3: "With a simple monthly subscription and the flexibility to pause anytime, accessing designer clothes and discovering global brands is effortless.",
  introduceParagraph4: "And the best part? You can purchase your beloved pieces at up to 75% off to keep them forever.",
  introduceButtonJoinNow: "JOIN NOW",

  planPricingSubtitle: "BNTO - THE EASIEST CLOTHING RENTAL",
  planPricingMainTitle: "PLAN & PRICING",
  planPricingStep1Title: "Choose Your 6",
  planPricingStep1Desc: "Four tops and the same dress in two sizes? Six pairs of jeans? One of everything? It's your call",
  planPricingStep2Title: "Wear Them on Repeat",
  planPricingStep2Desc: "They're all yours for the next month. Don't worry about laundry or repairs—it's included in the plan",
  planPricingStep3Title: "Buy, Return + Refill",
  planPricingStep3Desc: "If you fall in love with something, buy it for as much as 75% off. Send back the rest by your billing date and repeat.",
  planPricingWhiteBlockLeftTop: "6",
  planPricingWhiteBlockLeftBottom: "items / BNTO",
  planPricingWhiteBlockMiddleTop: "$98",
  planPricingWhiteBlockMiddleBottom: "/month",
  planPricingButtonJoinNow: "JOIN NOW",
  planPricingButtonMoreInfo: "MORE INFO",

  homeBrandLogosSubtitle: "FINELY CURATED",
  homeBrandLogosMainTitle: "DESIGNER BRANDS",

  homeBrandCarouselSubtitle: "FINELY CURATED",
  homeBrandCarouselMainTitle: "DESIGNER BRANDS",

  homeCollectionsSubtitle: "EXPLORE",
  homeCollectionsMainTitle: "BNTO COLLECTIONS",

  homeAvailableItemsCarouselSubtitle: "AS SEEN ON",
  homeAvailableItemsCarouselMainTitle: "BNTO AND YOU",

  homeEmailRegisterSubtitle: "STAY IN THE LOOP",
  homeEmailRegisterMainTitle: "JOIN THE BNTO LIST",
  homeEmailRegisterDesc: "Get a dash of delight delivered straight to your inbox by subscribing to our email list.",
  homeEmailRegisterPrivacyPolicy: "Private Policy",
  homeEmailRegisterEmailInputTitle: "Email",
  homeEmailRegisterEmailInputPlaceholder: "",
  homeEmailRegisterButtonSubmit: "SUBMIT",
  homeEmailRegisterThankYou: "Thank you for joining the BNTO list!",

  streamerCoupon: "GET $20 OFF A PURCHASE",
  streamerCodeTipsStart: "Enter the code",
  streamerCodeTipsEnd: "at checkout.",
  streamerCode: "BNTOFAV",
  howDoesItWork: "HOW DOES IT WORK?",
  buyNow: "BUY NOW",
  close: "CLOSE",
  madeJustForYou: "MADE JUST FOR YOU",
  smartBNTO: "SMART BNTO",
  smartBNTODescription: "Discover our feature that recommends a curated 6-item order for your next month based on your style preferences, sizes, order history, wishlists, and current trends, all powered by advanced machine learning.",
  openMySmartBNTO: "OPEN MY SMART–BNTO",
  recentlyViewed: "RECENTLY VIEWED",
  yourWishlist: "YOUR WISHLIST",
  youMayAlsoLike: "YOU MAY ALSO LIKE",

  accountAccountOverview: "Account Overview",
  accountMyProfile: "My Profile",
  accountRentalHistory: "Rental History",
  accountMySubscription: "My Subscription",
  accountBillingAndReceipts: "Billing & Receipts",
  accountBonusCreditsAndReferrals: "Bonus Credits + Referrals",
}
